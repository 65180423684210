<template>
  <a-row type="flex" justify="center">
    <a-col :lg="18" :span="23">
      <div class="treaty">
        <h1>VLLSHOP平台合同</h1>
        <hr>
        <div class="content">
          <div>发布日期：2021年【12】月【17】日</div>
          <div>生效日期：2022年【1】月【1】日</div>
        </div>
        <br>
        <div class="subTitle">阅读提示</div>
        <br>
        <div class="noramlBottom">欢迎您使用<span class="bold">VLLSHOP SaaS</span>建站服务！</div>
        <div class="noramlBottom">为使用<span class="bold">VLLSHOP SaaS</span>建站服务，请您仔细阅读、理解并遵守本《VLLSHOP 平台合同》。</div>
        <div class="underline">本合同由您与<span class="bold">VLLSHOP SaaS</span>建站服务提供方（深圳市万鲸科技有限公司及其关联公司，合称“VLLSHOP”）共同缔结，具有合同效力。</div>
        <br>
        <div class="subTitle">重点提示内容</div>
        <br>
        <div>所有重点内容，包括但不限于相关免除<span class="bold">VLLSHOP</span>责任的条款、对用户权利进行限制的条款、约定争议解决方式和司法管辖的条款等，均采用<span class="underline"> “加粗加下划线”</span>的书写方式进行提示。您应当仔细、审慎、重点阅读并充分的理解各条款的内容。</div>
        <br>
        <div>为您已阅读、理解并同意签署本合同，表示您与<span class="bold">VLLSHOP</span>已达成协议关系，并自愿接受并遵守本合同的全部约定。如果您认为该等条款可能导致您在特定情况下的利益或权利受损，请您立即停止使用本服务。</div>
        <br>
        <h4>一、定义</h4>
        <div class="Contract_content">
          <table class="MuiTable-root">
            <tbody class="MuiTable-root Contract_tableBody">
              <tr class="MuiTableRow-root">
                <td class="MuiTableCell-root MuiTableCell-body">
                  “本合同”
                </td>
                <td class="MuiTableCell-root MuiTableCell-body">
                  指本由您与<span class="bold">VLLSHOP</span>签订的<span class="bold">VLLSHOP</span>平台合同
                </td>
              </tr>
              <tr class="MuiTableRow-root">
                <td class="MuiTableCell-root MuiTableCell-body">
                  “本服务”
                </td>
                <td class="MuiTableCell-root MuiTableCell-body">
                  指<span class="bold">Saas</span>建站技术服务，包括但不限于网络开店架设软件服务、管理后台系统服务、云端销售点信息管理系统搭建服务、客户管理系统搭建服务、<span class="bold">O2O</span>整合服务以及平台维护服务等。
                </td>
              </tr>
              <tr class="MuiTableRow-root">
                <td class="MuiTableCell-root MuiTableCell-body">
                  “协议目的”
                </td>
                <td class="MuiTableCell-root MuiTableCell-body">
                  指通过<span class="bold">VLLSHOP</span>提供的本服务，帮助您实现搭建网上商店，并通过网上商店向您的终端消费者售卖商品的目的。
                </td>
              </tr>
              <tr class="MuiTableRow-root">
                <td class="MuiTableCell-root MuiTableCell-body">
                  “第三方平台”
                </td>
                <td class="MuiTableCell-root MuiTableCell-body">
                  指您为通过网店向终端用户销售商品的目的，可能使用到的其他服务（包括但不限于物流服务、支付服务、广告投放服务等）的服务提供商，包括但不限于Paypal、Google、Facebook。
                </td>
              </tr>
              <tr class="MuiTableRow-root">
                <td class="MuiTableCell-root MuiTableCell-body">
                  “方案定价”
                </td>
                <td class="MuiTableCell-root MuiTableCell-body">
                  指<span class="bold">VLLSHOP</span>的套餐价格。域名为：<span class="underline">https://www.vllshop.com/price</span>。
                </td>
              </tr>
              <tr class="MuiTableRow-root">
                <td class="MuiTableCell-root MuiTableCell-body">
                  “服务期限”
                </td>
                <td class="MuiTableCell-root MuiTableCell-body">
                  指您根据方案定价所购买的套餐的使用期限。为避免歧义，特此说明套餐服务期并不等同于本合同期限。
                </td>
              </tr>
              <tr class="MuiTableRow-root">
                <td class="MuiTableCell-root MuiTableCell-body">
                  “用户协议”
                </td>
                <td class="MuiTableCell-root MuiTableCell-body">
                  指<span class="bold">VLLSHOP</span>平台所载的用户规则，详见：<span class="underline">https://www.vllshop.com/treaty/userAgreement</span>。
                </td>
              </tr>
              <tr class="MuiTableRow-root">
                <td class="MuiTableCell-root MuiTableCell-body">
                  “日”
                </td>
                <td class="MuiTableCell-root MuiTableCell-body">
                  指日历日，包含所有周末公休日、银行休息日、法定节假日等
                </td>
              </tr>
              <tr class="MuiTableRow-root">
                <td class="MuiTableCell-root MuiTableCell-body">
                  “人士”
                </td>
                <td class="MuiTableCell-root MuiTableCell-body">
                  指任何自然人或法人，包括任何个人、有限责任公司、法团、合伙企业、社团、股份有限公司、信托、非法团机构、政府机构或具有类似性质的其他实体。
                </td>
              </tr>
              <tr class="MuiTableRow-root">
                <td class="MuiTableCell-root MuiTableCell-body">
                  “<span class="bold">VLLSHOP</span>平台”
                </td>
                <td class="MuiTableCell-root MuiTableCell-body">
                  指VLLSHOP 官方网站，域名为：<span class="underline">https://www.vllshop.com</span>。
                </td>
              </tr>
              <tr class="MuiTableRow-root">
                <td class="MuiTableCell-root MuiTableCell-body">
                  “<span class="bold">VLLSHOP</span>平台规则”
                </td>
                <td class="MuiTableCell-root MuiTableCell-body">
                  包括在所有<span class="bold">VLLSHOP</span>平台已经发布及后续发布的全部协议、政策、规则、解读、公告等内容以及各平台在帮派、论坛、帮助中心、规则中心内发布的各类规则、实施细则、产品流程说明、公告等，包括但不限于《用户协议》、《隐私政策》以及<span class="bold">VLLSHOP</span>规则中心（<span class="underline">https://www.vllshop.com/RuleHome</span>）上发布和更新的各类规则。
                </td>
              </tr>
              <tr class="MuiTableRow-root">
                <td class="MuiTableCell-root MuiTableCell-body">
                  “套餐”
                </td>
                <td class="MuiTableCell-root MuiTableCell-body">
                  指方案定价中显示的含有服务内容和相关费用的服务包。
                </td>
              </tr>
              <tr class="MuiTableRow-root">
                <td class="MuiTableCell-root MuiTableCell-body">
                  “套餐费用”
                </td>
                <td class="MuiTableCell-root MuiTableCell-body">
                  指您在购买套餐时所支付的费用
                </td>
              </tr>
              <tr class="MuiTableRow-root">
                <td class="MuiTableCell-root MuiTableCell-body">
                  “终端用户”
                </td>
                <td class="MuiTableCell-root MuiTableCell-body">
                  指访问或通过您的网店购买商品的用户
                </td>
              </tr>
            </tbody>
          </table>
          <br>
          <h4>二、解释</h4>
          <div>1. 提到任何适用法律或任何法律时，或者提及适用法律或任何法律中的任何规定时，包括不时对该适用法律或法律作出的任何修改或变更。</div>
          <div>2. 提到任何合同、文件或批准时，包括不时对该合同、文件或批准作出修改、更新、补充、变更或替换。</div>
          <div>3. 提到本合同或其他合同或文件的一方时，包括该一方的继承人和经允许的受让人。</div>
          <div>4. 提到任何中国政府主管部门或政府机构时，包括国家、省、市和其他级别的该等部门或机构及任何继任的部门或机构。</div>
          <div>5. 标题仅为方便查阅，不影响对本合同的解释</div>
          <div>6. “包括”一词是指“包括但不限于”</div>
          <br>
          <h4>三、服务的范围</h4>
          <div><span class="bold">（一）服务的内容</span></div>
          <div><span class="bold">VLLSHOP</span>通过<span class="bold">VLLSHOP</span>平台向您提供本服务，本服务能实现的具体功能，将根据您所选择并付费的套餐种类来决定。您使用本服务时，应自行准备和负担所有所需的计算机设备、网线及网络。</div>
          <br>
          <div><span class="bold">（二）VLLSHOP提供的配套服务</span></div>
          <div>在您使用本服务过程中，VLLSHOP将会提供7x24小时不间断负责主机运维、保障数据、存储、网络安全可靠维护；</div>
          <br>
          <h4>四、账号的使用</h4>
          <div><span class="bold">（一）您应当具有的资质</span></div>
          <div>1. 具备签署本合同的能力</div>
          <div class="indent">您使用<span class="bold">VLLSHOP</span>平台进行注册前及注册后在使用本服务期间，您应当为具备完全民事权利能力和行为能力的人士。</div>
          <div>2.并未受到其他方的限制</div>
          <div class="indent">您在此保证，任何国家、地区或国际组织并未对您实施贸易限制、经济制裁或者任何适用法律的限制，同时您未向被实施前述行为的人士提供任何资金、商品或服务。</div>
          <div>3.您的信息的更新及真实、有效性</div>
          <div class="indent">(1)您承诺，在使用本服务过程中，您应当按照VLLSHOP要求，准确且完整提供您的信息及材料。</div>
          <div class="indent">(2)您理解，其具有保证提供前述信息的真实及有效；若前述信息发生更新、或者有适用法律明确要求VLLSHOP必须对您及其他VLLSHOP平台用户进行信息核查的情况下，VLLSHOP有权对您的信息进行核查，您有义务配合并提供最新、真实、有效的信息。</div>
          <div class="indent">(3)若您并未根据VLLSHOP的要求提供前述信息，或提供的信息存在严重虚假或被相关司法行政机关核查认定为无效信息的，您应当承担因此导致的不利后果以及VLLSHOP的全部损失。</div>
          <div class="indent">(4)若您的经营主体、股东信息、经营地址及相关代理人、指定人员等基本信息发生变化，您应当在五（5）个工作日内书面通知<span class="bold">VLLSHOP</span>。</div>
          <br>
          <div><span class="bold">（二）账号的注册</span></div>
          <div class="indent">您可以通过VLLSHOP平台注册并使用一个VLLSHOP账号；若VLLSHOP通过合理判断，认为您存在不当注册或不当使用多个账号的情形，VLLSHOP可以采取冻结、关闭账号、暂停或拒绝提供服务等措施，给VLLSHOP及相关方造成损失的，您应承担赔偿责任。</div>
          <br>
          <div class="indent">您同意并知悉，您通过电子邮箱加密码以及手机号码加验证码方式注册并登录VLLSHOP，VLLSHOP将以此识别您的账号信息，相关个人信息的收集情况详见《VLLSHOP隐私政策》。您声明并保证，在注册VLLSHOP账号成为用户时，您提交的所有注册信息是准确和真实的，不应使用他人的信息或者虚假信息注册账号。如果您用于注册账号的信息有变化，您在任何时候必须及时更新您的账号信息并保持其准确性</div>
          <br>
          <div><span class="bold">（三）账号的使用</span></div>
          <div class="indent">您不得以任何方式转让（包括但不限于赠与、租借、转让、出售等方式）账号，否则，您私自进行转让所导致的一切后果由您自行承担。</div>
          <br>
          <div class="indent">当您发现您的VLLSHOP账号被未经其授权的第三方使用或存在其他账号安全问题时，应立即有效通知VLLSHOP，要求暂停该账号的服务。您理解VLLSHOP对用户的任何请求采取行动均需要合理时间，且VLLSHOP应您的请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大。除VLLSHOP存在法定过错外，VLLSHOP不承担因上述事宜产生的责任。</div>
          <br>
          <div><span class="bold">（四）账号的绑定</span></div>
          <div class="indent">您购买的套餐仅限于购买账号或该账号下的子账号使用。若您系通过线下或其他渠道购买的套餐，则将由VLLSHOP将您的账号和套餐进行绑定。并在绑定完成后通知您。</div>
          <br>
          <div><span class="bold">（五）账号的注销</span></div>
          <div class="indent">您有权利在任何时间停止使用账户并向VLLSHOP客服人员提出申请注销账号。在注销账号之后，VLLSHOP将依照用户协议等VLLSHOP平台规则停止为您提供所有的服务，并依照适用法律删除您的个人信息，但适用法律另有规定、司法行政机关另有要求的除外。</div>
          <br>
          <h4>五、服务费用和付款方式</h4>
          <div><span class="bold">（一）服务费用</span></div>
          <div class="indent">您可以根据您的需要，根据方案计价选择相应的套餐类型，并根据所选择的套餐类型支付服务费用。</div>
          <br>
          <div><span class="bold">（二）服务期限</span></div>
          <div class="indent">若您使用的是免费试用版服务，本合同下服务期限，自VLLSHOP为您开通VLLSHOP账号之日起算；若您使用的是付费版服务，本合同下服务期限，自您支付服务费用之日起计算。</div>
          <br>
          <div class="indent">若您系通过线下或其他方式购买VLLSHOP套餐并支付费用，则本合同下服务期限自套餐激活之日起计算。若您在VLLSHOP为您绑定完套餐后24小时内未激活套餐，则套餐将于24小时后起自动激活并开始计算服务期限。</div>
          <br>
          <div class="indent">双方关于服务费用、服务期限、优惠内容另有书面约定的，以书面约定内容为准。</div>
          <br>
          <div><span class="bold">（三）付款方式</span></div>
          <div class="indent">您同意采用微信支付或信用卡支付的方式支付服务费用，并同意承担付款所产生的手续费。</div>
          <br>
          <div><span class="bold">（四）不予缴纳的后果</span></div>
          <div class="indent">您应按照所选套餐类型支付使用本服务所产生的费用。如您未按VLLSHOP付款公告及时付款或欠费，VLLSHOP有权按本合同第八条向您主张违约责任。</div>
          <br>
          <h4>六、用户使用规范</h4>
          <div>您在使用VLLSHOP或通过网店向终端用户提供服务时，不得存在下列行为：</div>
          <br>
          <div class="bold">1. 在网店上上传、发布任何违反法律法规、公序良俗或具有错误、不准确、误导性、诽谤性的内容；</div>
          <div class="bold">2. 发布或传播垃圾邮件或传销内容；</div>
          <div class="bold">3. 采取扰乱经济秩序、不正当手段（包括但不限于开展欺诈或涉及非法、假冒或盗窃物品的交易，盗用其他人士账号、发布违法禁止广告）开展生产经营的，侵犯任何第三方的著作权、专利、商标、商业秘密或其他知识产权以及隐私权利；</div>
          <div class="bold">4. 通过VLLSHOP发布或传播可能损害VLLSHOP或其他用户合法权利的任何计算机病毒、蠕虫或任何其他技术；</div>
          <div class="bold">5. 诽谤、辱骂、骚扰、跟踪、威胁或以其他方式侵犯他人的合法权利（如隐私权）；</div>
          <div class="bold">6. 限制或禁止任何其他用户使用VLLSHOP服务；</div>
          <div class="bold">7. 在未经用户同意的情况下获取或以其他方式收集用户的任何数据或信息（包括但不限于个人信息）；</div>
          <div class="bold">8. 修改、改编或攻击VLLSHOP或通过修改、创建其他网站或移动设备应用程序，并暗示该网站或应用程序与VLLSHOP有关；</div>
          <div class="bold">9. 违反第三方平台规则或侵害第三方平台权益；</div>
          <div class="bold">10. 其他违反法律法规规定或VLLSHOP平台规则的行为。</div>
          <br>
          <h4>七、违约处理</h4>
          <div><span class="bold">（一）违约的认定</span></div>
          <div>若发生以下情形，视为您违约：</div>
          <div>(1) 违反适用法律的规定使用VLLSHOP平台；或</div>
          <div>(2) 违反本合同或VLLSHOP平台规则。</div>
          <br>
          <div><span class="bold">（二）违约责任</span></div>
          <div class="bold">1. 若因您的行为致使VLLSHOP和/或其股东、法定代表人、董事、员工、高管（以下简称“关联方”）遭受损失（包括但不限于直接经济损失、商誉损失及向第三方支付的赔偿金、诉讼费、律师费），您应当赔偿VLLSHOP和/或关联方前述的全部损失。</div>
          <div class="bold">2. 若因您的行为致使第三方向VLLSHOP和/或其关联方主张权利，致使VLLSHOP和/或关联方承担向第三方的赔偿责任，VLLSHOP和/或关联方可就前述的全部损失向您追偿。</div>
          <div class="bold">3.当您违约或VLLSHOP基于合理判断认为您出现了违约的情形，VLLSHOP有权根据您的违约情况选择以下一种或多种方式来维护VLLSHOP的权益：</div>
          <div>(1)删除您上传、发布的内容；</div>
          <div>(2)中止、暂停提供本服务；</div>
          <div>(3)冻结或关闭您的账户；</div>
          <div>(4)立即终止本合同。</div>
          <br>
          <div><span class="bold">（三）您同意，若您拖欠本合同下应付款项，VLLSHOP可以通过其关联公司所控制的您的财产进行清偿。同样，若您拖欠VLLSHOP关联公司款项，则VLLSHOP亦有权从套餐使用天数中扣除与欠费金额相应比例的使用天数。</span></div>
          <br>
          <h4>八、不可分性</h4>
          <div>您同意并了解，基于跨境电商的快速发展，您与VLLSHOP签订的本合同列明的条款并未能涵盖您所有的权利以及义务。<span class="underline">VLLSHOP不定期发布、更新的用户协议、隐私政策、VLLSHOP平台规则均为本合同的一部分，与本合同不可分割且具有同等的法律效力。您应当及时查看该等文件，了解您的该等权利义务的变更。</span></div>
          <br>
          <h4>九、合同终止</h4>
          <div><span class="bold">（一）合同终止的情况</span></div>
          <div>1. 您终止本合同</div>
          <div class="indent">本合同服务期限内，您有权提前十五（15）日书面通知VLLSHOP要求终止本合同。</div>
          <div>2. VLLSHOP终止本合同</div>
          <div class="indent underline">(1)VLLSHOP可根据实际运营需要，提前于三（3）个月书面形式通知您终止本合同。</div>
          <div class="indent underline">若您有以下行为的，VLLSHOP有权立即终止本合同并以本合同第十四条所载的方式通知您：</div>
          <div class="indent underline">A.您进入解散、破产清算程序，或司法行政机关对您违反适用法律作出警告、罚款等相关措施或处分；</div>
          <div class="indent underline">B.VLLSHOP依据相关违约条款终止本合同的；</div>
          <div class="indent underline">C.其他原因导致终止本合同的</div>
          <br>
          <div><span class="bold">（二）终止后的后果</span></div>
          <div>1. 合同终止后VLLSHOP的权利</div>
          <div class="indent">本合同终止后，VLLSHOP仍然有权享有以下权利：</div>
          <div class="indent">(1) VLLSHOP有权拒绝向您提供您账号内的任何信息；</div>
          <div class="indent">(2) 若非由于VLLSHOP实际运营需要终止本合同的，您仍应支付未结清的相关费用；及</div>
          <div class="indent">(3) 若因本合同终止前您的任何违约行为导致VLLSHOP遭受损失的，VLLSHOP仍有权依照本合同追究您违约责任。</div>
          <div>2.费用退还</div>
          <div class="indent">(1) 若因VLLSHOP实际运营需要终止本合同的，您有权要求VLLSHOP将您网店账号剩余的使用天数转换为退费金额退还予您，退费金额为根据您在购买套餐时所支付的价格按照未使用天数对应比例折算成的金额。除上述退还费用以外，VLLSHOP不负责任何赔偿责任</div>
          <div class="indent">(2) 若您主动终止本合同，或VLLSHOP根据第九条第（一）款第2项列出的情形终止本合同的，VLLSHOP有权不予退还您前期支付的费用。</div>
          <br>
          <h4>十、效力</h4>
          <div><span class="bold">（一）合同生效日</span></div>
          <div>本合同自您按照注册页面提示填写信息、完成全部注册程序，且阅读并勾选同意本协议或以其他确认的形式（包括但不限于点击同意、支付费用、继续使用本服务）接受本合同时生效，上述确认的形式视为您已阅读、理解并同意签署本合同，表示您与VLLSHOP已达成协议关系，并自愿接受并遵守本合同的全部约定。除非根据本合同第九条终止，否则本合同将持续有效。</div>
          <br>
          <div><span class="bold">（二）效力优先性</span></div>
          <div>若双方对本合同进行修订、修改，则新的文本效力优于原文本；新文本的内容与本合同存在冲突的，依照新文本进行理解、适用。</div>
          <br>
          <div><span class="bold">（三）继续履行</span></div>
          <div>在解决争议期间，除争议事项外，各方应继续履行本合同。</div>
        </div>
      </div>
    </a-col>
  </a-row>

</template>

<script>
export default {
  name: 'Contract'
}
</script>

<style scoped>

</style>
